//DEFAULTS
import "imports/core-js/stable";
import "imports/regenerator-runtime/runtime";
import overlay from 'site/overlay'
import site from 'site/site'
import global from 'site/global'
import gallery from 'site/gallery'
import home from 'site/home'
import soundtrack from 'site/soundtrack'
import screenings from 'site/screenings'
import siteScroll from 'site/scroll'
import mobileNav from 'site/mobileNav'
import siteAOS from 'site/site.aos'
import customDropdowns from 'site/customDropdowns'
$(document).ready(function() {
    mobileNav.init()
    site.init()
    global.init()
    overlay.init()
    siteScroll.init()
    gallery.init()
    screenings.init()
    soundtrack.init()
    home.init()
    customDropdowns.init()
    setTimeout( function() {
        siteAOS.init()
        siteAOS.refreshAOS()
    },500)
})
