import overlay from 'site/overlay'
import siteAOS from 'site/site.aos'

const dayjs = require('imports/dayjs')
const AdvancedFormat = require('imports/dayjs/plugin/advancedFormat')
const utc = require('imports/dayjs/plugin/utc')
const timezone = require('imports/dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(AdvancedFormat)
import siteCommon from 'site/site-common'

let screenings = {
    screeningIdObject: null,
    screeningsByTheater: null,
    cityList: [],
    clickedSubmitOnce: false,
    isSubmitting: false,
    serverRoot: '',
    init: async function () {
        if ($('body').hasClass('dm')) {
            screenings.serverRoot = 'https://api.paramount.dev.realpie.com/api/v1/'
        } else {
            screenings.serverRoot = 'https://paramount.screenings.realpie.com/api/v1/'
        }

        if ($('body').hasClass('page-screenings') && screeningsLinkageSlug) {
            //
            $('#topnav-film-change-mobile').on('change', screenings.topNavChangeFilmMobile)
            $('#topnav-film-change-desktop').on('change', screenings.topNavChangeFilmDesktop)

            //https://api.paramount.dev.realpie.com/api/v1/films/top-gun-maverick/screenings_by_theater/
            let apiUrl = `${screenings.serverRoot}films/${screeningsLinkageSlug}/screenings_by_theater/`
            if (site_segment3) {
                apiUrl = `${screenings.serverRoot}screenings/${site_segment3}/`
            }

            let doManuallyPopulate = false
            if (doManuallyPopulate) {
                screenings.manualPopulate()
                screenings.convertToScreeningsListingsDisplay()
            } else {
                $.ajax({
                    url: apiUrl,
                    type: 'GET',
                    dataType: 'json',
                    crossDomain: true,
                    success: function (response, textStatus) {
                        if (site_segment3) {
                            response.screenings = [response]
                            screenings.screeningsByTheater = [
                                response
                            ]
                        } else {
                            screenings.screeningsByTheater = response
                        }
                        screenings.convertToScreeningsListingsDisplay()
                    },
                    error: function (jqXHR, textStatus) {

                    }
                });
            }


        }
    },
    topNavChangeFilmMobile: function () {
        let val = $('#topnav-film-change-mobile').val()
        if (val) {
            window.location = '/' + val + '/screenings'
        }
    },
    topNavChangeFilmDesktop: function () {
        let val = $('#topnav-film-change-desktop').val()
        if (val) {
            window.location = '/' + val + '/screenings'
        }
    },
    topNavChangeLocationMobile: function () {
        let val = $('#topnav-location-change-mobile').val()
        screenings.topNavChangeLocation(val)
    },
    topNavChangeLocationDesktop: function () {
        let val = $('#topnav-location-change-desktop').val()
        screenings.topNavChangeLocation(val)
    },
    topNavChangeLocation: function (val) {
        if (val) {
            $('.theater-row').hide()
            $('.theater-row-' + val).show()
        } else {
            $('.theater-row').show()
        }
        siteAOS.refreshAOS()
    },


    convertToScreeningsListingsDisplay: function () {
        let finalHtml = ''
        if (screenings.screeningsByTheater) {
            for (let i = 0; i < screenings.screeningsByTheater.length; i++) {
                let obj = screenings.screeningsByTheater[i]

                //screenings
                let screeningsHtml = ''
                for (let ii = 0; ii < obj.screenings.length; ii++) {
                    let obj2 = obj.screenings[ii]
                    let dtString = obj2.local_datetime
                    dtString = dtString.slice(0, -6) //kill timezone for display
                    let dt = new Date(dtString)
                    let dtDate = dayjs(dt).format('dddd, MMM D')
                    let dtTime = dayjs(dt).format('hh:mm A')
                    let isOpen = (obj2.button_status === 'enabled')
                    let btnLabel = obj2.button_label
                    let btnClass = (isOpen) ? 'open overlay-hook' : 'closed'

                    //qa
                    let qaIcon = ''
                    if (obj2.notes) {
                        if (obj2.notes.qa && (obj2.notes.qa.title || obj2.notes.qa.description)) {
                            qaIcon = `<div class="qaIcon">
                                <div class="icon"><img src="${iconComments}"/></div>
                                <span>Q&A</span>
                            </div>`
                        }
                    }
                    let temp2 = `
                        <div class="item rte no-max-width">
                            <h4>${dtDate}</h4>
                            <p>At ${dtTime} ${obj2.local_timezone}</h4>
                            <div class="rsvp">
                                <button class="general-button ${btnClass}" data-overlay='rsvp' data-rsvp-id="${obj2.id}">${btnLabel}</button>
                                ${qaIcon}
                            </div>
                        </div>
                    `
                    screeningsHtml += temp2
                }

                // theater row
                if (obj.theater) {
                    //city list
                    screenings.cityList.push(
                        {
                            label: obj.theater.city.name,
                            slug: obj.theater.city.slug,
                        }
                    )

                    let address = screenings.convertTextToBr(obj.theater.address)
                    let phone = (obj.theater.phone) ? obj.theater.phone : ''
                    let theaterTitle = (obj.theater.title) ? obj.theater.title : ''
                    if (!theaterTitle) {
                        theaterTitle = (obj.theater.name) ? obj.theater.name : ''
                    }
                    let temp = `
                    <div class="theater-row theater-row-${obj.theater.city.slug}">
                        <div class="col-theaters rte no-max-width" data-aos="custom-fade-in">
                            <div class="p1">
                                <h3>${theaterTitle}</h3>
                            </div>
                            <div class="p2">
                                <p class="txt-address">${address}</p>
                                <p class="phone">${phone}</p>
                            </div>
                        </div>
                        <div class="col-screenings" data-aos="custom-fade-in">${screeningsHtml}</div>
                    </div>
                `
                    finalHtml += temp
                }

            }
        }

        //city list cleanup
        //screenings.cityList
        screenings.cityList = screenings.unique(screenings.cityList)
        let citiesHtml = '<option value="">All</option>'
        for (let i = 0; i < screenings.cityList.length; i++) {
            let obj = screenings.cityList[i]
            citiesHtml += `<option value="${obj.slug}">${obj.label}</option>`
        }
        $('#topnav-location-change-mobile').html(citiesHtml)
        $('#topnav-location-change-mobile').on('change', screenings.topNavChangeLocationMobile)
        $('#topnav-location-change-desktop').html(citiesHtml)
        $('#topnav-location-change-desktop').on('change', screenings.topNavChangeLocationDesktop)

        if (finalHtml === '') {
            finalHtml = "<div style='margin-top:50px' class='rte'><p>Coming soon.</p></div>"
        }
        $('#all-listings').html(finalHtml)
        $('#all-listings .overlay-hook').on('click', overlay.doOverlayHandle)
        siteAOS.refreshAOS()
    },

    unique: function (obj) {
        var uniques = [];
        var stringify = {};
        for (var i = 0; i < obj.length; i++) {
            var keys = Object.keys(obj[i]);
            keys.sort(function (a, b) {
                return a - b
            });
            var str = '';
            for (var j = 0; j < keys.length; j++) {
                str += JSON.stringify(keys[j]);
                str += JSON.stringify(obj[i][keys[j]]);
            }
            if (!stringify.hasOwnProperty(str)) {
                uniques.push(obj[i]);
                stringify[str] = true;
            }
        }
        return uniques
    },

    populateRsvpOverlay(rsvpId) {
        //screenings.manualPopulateRsvp()
        let apiUrl = `${screenings.serverRoot}screenings/${rsvpId}/`
        $.ajax({
            url: apiUrl,
            type: 'GET',
            dataType: 'json',
            crossDomain: true,
            success: function (response, textStatus) {
                screenings.screeningIdObject = response
                screenings.populateRsvpOverlay2()
            },
            error: function (jqXHR, textStatus) {

            }
        });

    },
    populateRsvpOverlay2() {
        let obj = screenings.screeningIdObject
        let address = screenings.convertTextToBr(obj.theater.address)
        let dtString = obj.local_datetime
        dtString = dtString.slice(0, -6) //kill timezone for display
        let dt = new Date(dtString)
        let dtDate = dayjs(dt).format('dddd, MMM D')
        let dtTime = dayjs(dt).format('hh:mm A')

        //affiliate dropdown
        let affiliateDropdown = '<option value="">Affiliation*</option>'
        for (let i = 0; i < obj.available_affiliations.length; i++) {
            let tobj = obj.available_affiliations[i]
            affiliateDropdown += `<option value="${tobj.slug}">${tobj.name}</option>`
        }

        //guests dropdown
        let guestsDropdown = '<option value="">Number of Guests</option>'
        for (let i = 0; i <= obj.guests_limit; i++) {
            guestsDropdown += `<option value="${i}">${i}</option>`
        }

        let driveOn = ''
        if (obj.theater.notes) {
            driveOn = `<div class="sec sec-driveon">
                <div class="icon"><img src="${iconBadge}"/></div>
                ${obj.theater.notes}
            </div>`
        }

        let qa = ''
        let reception = ''
        if (obj.notes) {
            if (obj.notes.qa && (obj.notes.qa.title || obj.notes.qa.description)) {
                qa = `<div class="sec sec-qa">
                <div class="icon"><img src="${iconComments}"/></div>
                <h5>${obj.notes.qa.title}</h5>                
                ${obj.notes.qa.description}
            </div>`
            }
            if (obj.notes.reception && (obj.notes.reception.title || obj.notes.reception.description)) {
                reception = `<div class="sec sec-reception">
                <div class="icon"><img src="${iconWine}"/></div>
                <h5>${obj.notes.reception.title}</h5>                
                ${obj.notes.reception.description}
            </div>`
            }
        }

        //FINAL
        let finalHtml = ''
        finalHtml = `
            <div class="rsvp-inner">
                <div class="scrim"></div>
                <div class="columns">
                    <div class="col-left rte no-max-width">
                        <h3>RSVP</h3>
                        <div class="line"></div>
                        <div class="sec sec-film">
                            <div class="icon"><img src="${iconLocationDot}"/></div>
                            <h5>${obj.film.title}</h5>
                            <p>${obj.theater.name}</p>
                            <p>${address}</p>
                        </div>
                        <div class="sec sec-time">
                            <div class="icon"><img src="${iconClock}"/></div>
                            <h5>${dtDate}</h5>
                            <p>At ${dtTime} ${obj.local_timezone}</p>
                        </div>
                        ${qa}
                        ${reception}
                    </div>
                    <div class="col-right">
                        <div class="fields">
                            <div class="field-row">
                                <div class="field-row-element">
                                    <input id="first_name" name="first_name" type="text" maxlength="200" minlength="1" required placeholder="First Name*" />
                                </div>
                                <div class="field-row-element">
                                    <input id="last_name" name="last_name" type="text" maxlength="200" minlength="1" required placeholder="Last Name*" />
                                </div>
                            </div>
                            <div class="field-row">
                                <div class="field-row-element">
                                    <input id="phone" name="phone" type="tel" maxlength="50" placeholder="Phone Number" />
                                </div>
                                <div class="field-row-element">
                                    <input id="email" name="email" type="email" maxlength="50" required placeholder="Email*"/>
                                </div>
                            </div>
                            <div class="field-row">
                                <div class="field-row-element">
                                    <select required id="affiliation" >
                                       ${affiliateDropdown}
                                    </select>
                                </div>
                                <div class="field-row-element">
                                    <select id="friends">
                                        ${guestsDropdown}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="rte no-max-width">
                            ${driveOn}
                            <div class="sec sec-submit">
                                <button id="submit-hook" class="general-button inverted">Submit</button>
                                <div id="rsvp-error-messages">
                                    <p class="error"><small>Please double check the fields above are correct</small></p>
                                </div>
                            </div>
                            <div class="sec sec-finalnote">
                                <p>Seating is on a first-come, first served basis. Guild membership card will be required upon check-in. By providing your personal information, you acknowledge our privacy practices described <a target="_blank" href="https://privacy.paramount.com/policy">here</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
        `

        //FINAL
        $('#overlay-rsvp-hook').html(finalHtml)
        screenings.clickedSubmitOnce = false
        screenings.isSubmitting = false
        screenings.clearAllErrors()
        $('#submit-hook').on('click', screenings.doSubmit)
        $('#overlay-rsvp-hook input').on('keyup', screenings.validateForm)
        $('#overlay-rsvp-hook textarea').on('keyup', screenings.validateForm)
        $('#overlay-rsvp-hook select').on('change', screenings.validateForm)
        overlay.resize()
    },

    doSubmit() {
        screenings.clickedSubmitOnce = true
        let ret = screenings.validateForm()
        if (ret) {
            let answers = screenings.getAnswersObject()
            if (!screenings.isSubmitting) {
                screenings.isSubmitting = true
                let turl = `${screenings.serverRoot}rsvps/`
                //let turl = "/screenings/rsvp?nocache="+Math.random();
                $.ajax({
                    type: "POST",
                    //dataType : 'json',
                    url: turl,
                    data: answers,
                    error: function (xhr, ajaxOptions, thrownError) {
                        let data = (xhr) ? xhr.responseJSON : {}
                        if (
                            data
                            && data.error
                            && data.error.details
                            && data.error.details.email
                            && data.error.details.email[0] === 'Email has already registered.'
                        ) {
                            // actually is a success
                            screenings.showResult(data, true);
                        } else {
                            console.log("submit error")
                            $('#rsvp-error-messages').html('<p class="error"><small>An unknown error has occured.  Please try again later.</small></p>')
                            $('#rsvp-error-messages').show()

                            screenings.isSubmitting = false
                            console.log(data.status)
                            console.log(ajaxOptions)
                            console.log(thrownError)
                        }
                        siteCommon.scOpenTopOfPage()
                    },
                    success: function (data) {
                        //data
                        if (!data.error) {
                            screenings.showResult(data, false);
                        } else {
                            console.log('data.error', data.error)
                            if (
                                data.error.details
                                && data.error.details.email
                                && data.error.details.email === 'Email has already registered.'
                            ) {
                                // actually is a success
                                screenings.showResult(data, true);
                            } else {
                                let resp = data.error.message
                                $('#rsvp-error-messages').html('<p class="error"><small>' + resp + '</small></p>');
                                $('#rsvp-error-messages').show();
                            }

                        }
                        screenings.isSubmitting = false
                        siteCommon.scOpenTopOfPage()
                    },
                    beforeSend: function (xhr, settings) {
                        //xhr.setRequestHeader("X-CSRFToken", screenings.csrfmiddlewaretoken);
                    }
                });
            }
        }
    },
    showResult(data, alreadyRegistered = true) {
        if (alreadyRegistered) {
            $('#overlay-rsvp-hook .columns').html(`
            <div class="thanks">
                <div class="rte">
                    <h2>Already Registered!</h2>
                    <div class="line"></div>
                    <p>You are already registered.  We've used your existing reservation.  No new updates have been made to your registration.</p>
                </div>
            </div>           
        `)
        } else {
            $('#overlay-rsvp-hook .columns').html(`
            <div class="thanks">
                <div class="rte">
                    <h2>Thank You!</h2>
                    <div class="line"></div>
                    <p>You've been confirmed for this screening.</p>
                </div>
            </div>           
        `)
        }

    },

    clearAllErrors: function () {
        $('#overlay-rsvp-hook input').removeClass('error')
        $('#overlay-rsvp-hook select').removeClass('error')

        $('#rsvp-error-messages').hide();
        $('#submit-hook').removeClass('error');
    },

    validateForm: function () {
        let cont = true
        $('.screenings-popup #signup-state-error').hide()
        if (screenings.clickedSubmitOnce) {
            //clear errors
            screenings.clearAllErrors()

            //check form
            let answers = screenings.getAnswersObject()

            //fn
            if (answers.first_name === '') {
                cont = false
                $('#first_name').addClass('error');
            }
            //ls
            if (answers.last_name === '') {
                cont = false
                $('#last_name').addClass('error');
            }
            /*
            //phone
            if (answers.phone === '' || answers.phone.length<10 ) {
                cont=false;
                $('#selector-phone').addClass('error');
            }
            */

            //email
            if (siteCommon.isValidEmail(answers.email) === false) {
                cont = false
                $('#email').addClass('error')
            }
            //affiliation
            if (answers.affiliation === '') {
                cont = false;
                $('#affiliation').addClass('error');
            }
            //num guests
            if (answers.friends === '') {
                //cont=false;
                //$('#selector-guests').addClass('error');
            }

            //legal
            /*
            if (answers.legal === "") {
                cont=false;
                $('#signup-legal').addClass('error');
            }
            */

            //make button grey
            if (cont === false) {
                $('#submit-hook').addClass('error');
                $('#rsvp-error-messages').html('<p class="error"><small>Please double check the fields above are correct</small></p>')
                $('#rsvp-error-messages').show()
            }

        } else {
            cont = false
        }
        return cont
    },

    getAnswersObject: function () {
        //var csrfmiddlewaretoken = $( "input[name='csrfmiddlewaretoken']" ).val();
        let answers = {
            'screening': screenings.screeningIdObject.id,
            //'csrfmiddlewaretoken':csrfmiddlewaretoken,
            'first_name': '',
            'last_name': '',
            'phone': '',
            'email': '',
            'affiliation': '',
            'friends': '',
            'agreed_to_terms': true
        };

        answers.first_name = $('#first_name').val()
        answers.last_name = $('#last_name').val()
        answers.phone = $('#phone').val()
        answers.email = $('#email').val()
        answers.affiliation = $('#affiliation').val()
        answers.friends = $('#friends').val()

        if (answers.affiliation === undefined || answers.affiliation === null) {
            answers.affiliation = '';
        }
        if (!answers.friends) {
            answers.friends = 0;
        }

        /*
        var friendsNum = parseFloat(answers.friends);
        for (var i=1;i<=friendsNum;i++) {
            answers['guest_name_' + i]=$('#guest-name-' + i + ' input').val();
        }
         */
        return answers
    },


    convertTextToBr: function (txt) {
        return txt.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },

    manualPopulate: function () {
        screenings.screeningsByTheater = [{
            "theater": {
                "id": "57cff406-59fd-a020-494a-4f7290442667",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/57cff406-59fd-a020-494a-4f7290442667/",
                "title": "Dolby Screening Room Burbank",
                "name": "Dolby Screening Room Burbank",
                "city": {
                    "id": "dfee9006-59fd-a020-494a-22070644261a",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/dfee9006-59fd-a020-494a-22070644261a/",
                    "title": "Burbank",
                    "name": "Burbank",
                    "slug": "burbank",
                    "timezone": "America/Los_Angeles"
                },
                "address": "3601 W Alameda Ave\r\nBurbank, CA 91505",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "734d5d06-59fd-a020-4915-df92fe49b954",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/734d5d06-59fd-a020-4915-df92fe49b954/",
                "local_datetime": "2022-11-21T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-11-22T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "JOIN WAITLIST",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }, {
            "theater": {
                "id": "4c58ee06-59fd-a020-4909-dbdc2049a4c0",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/4c58ee06-59fd-a020-4909-dbdc2049a4c0/",
                "title": "Dolby Hollywood",
                "name": "Dolby Hollywood",
                "city": {
                    "id": "8323a006-59fd-a020-4903-6b054649a477",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/8323a006-59fd-a020-4903-6b054649a477/",
                    "title": "Hollywood",
                    "name": "Hollywood",
                    "slug": "hollywood",
                    "timezone": "America/Los_Angeles"
                },
                "address": "6321 Hollywood Blvd, \r\nHollywood, CA 90028",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "376bbb06-59fd-a020-4906-ab2bf449b94e",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/376bbb06-59fd-a020-4906-ab2bf449b94e/",
                "local_datetime": "2022-11-14T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-11-15T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "JOIN WAITLIST",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }, {
            "theater": {
                "id": "a88da306-59fd-a020-4957-c2439e49a388",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/a88da306-59fd-a020-4957-c2439e49a388/",
                "title": "Odeon Luxe",
                "name": "Odeon Luxe",
                "city": {
                    "id": "1fa1ac06-59fd-a020-494a-2485a84426fa",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/1fa1ac06-59fd-a020-494a-2485a84426fa/",
                    "title": "London",
                    "name": "London",
                    "slug": "london",
                    "timezone": "Europe/London"
                },
                "address": "24-26 Leicester Square\r\nLondon WC2H 7JY",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "54e57906-59fd-a020-4950-3d99d849bd5b",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/54e57906-59fd-a020-4950-3d99d849bd5b/",
                "local_datetime": "2022-11-23T19:00:00+00:00",
                "local_timezone": "GMT",
                "utc_datetime": "2022-11-23T19:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "3e118506-59fd-a020-4962-52e66e49bd1f",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/3e118506-59fd-a020-4962-52e66e49bd1f/",
                "local_datetime": "2022-11-30T19:00:00+00:00",
                "local_timezone": "GMT",
                "utc_datetime": "2022-11-30T19:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "3ed04706-59fd-a020-4975-78d1e049bd22",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/3ed04706-59fd-a020-4975-78d1e049bd22/",
                "local_datetime": "2022-12-07T18:30:00+00:00",
                "local_timezone": "GMT",
                "utc_datetime": "2022-12-07T18:30:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "de8e4606-59fd-a020-4983-b17fbe49bd14",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/de8e4606-59fd-a020-4983-b17fbe49bd14/",
                "local_datetime": "2022-12-19T19:00:00+00:00",
                "local_timezone": "GMT",
                "utc_datetime": "2022-12-19T19:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }, {
            "theater": {
                "id": "6257ca06-7394-f640-312d-b0c1f45c66cd",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/6257ca06-7394-f640-312d-b0c1f45c66cd/",
                "title": "AMC Burbank",
                "name": "AMC Burbank",
                "city": {
                    "id": "634eb806-59fd-a020-494a-309f1e442649",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/634eb806-59fd-a020-494a-309f1e442649/",
                    "title": "Los Angeles",
                    "name": "Los Angeles",
                    "slug": "los-angeles",
                    "timezone": "America/Los_Angeles"
                },
                "address": "125 E Palm Ave, \r\nBurbank, CA 91502",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "13bb2d06-7394-f640-3145-87bd6e5c661b",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/13bb2d06-7394-f640-3145-87bd6e5c661b/",
                "local_datetime": "2022-12-13T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-12-14T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "a086f906-7394-f640-31c5-ccfee45c6615",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/a086f906-7394-f640-31c5-ccfee45c6615/",
                "local_datetime": "2022-12-22T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-12-23T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }, {
            "theater": {
                "id": "68365b06-59fd-a020-4947-cb7d5249852a",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/68365b06-59fd-a020-4947-cb7d5249852a/",
                "title": "AMC Century City",
                "name": "AMC Century City",
                "city": {
                    "id": "634eb806-59fd-a020-494a-309f1e442649",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/634eb806-59fd-a020-494a-309f1e442649/",
                    "title": "Los Angeles",
                    "name": "Los Angeles",
                    "slug": "los-angeles",
                    "timezone": "America/Los_Angeles"
                },
                "address": "10250 Santa Monica Blvd.\r\nLos Angeles, CA 90067",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "9e017906-59fd-a020-4921-96efc049b963",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/9e017906-59fd-a020-4921-96efc049b963/",
                "local_datetime": "2022-11-28T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-11-29T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "JOIN WAITLIST",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "43e17a06-7394-f640-316d-11a13a5c64e9",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/43e17a06-7394-f640-316d-11a13a5c64e9/",
                "local_datetime": "2022-12-05T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-12-06T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "c9a45d06-7394-f640-319c-b86de05c6682",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/c9a45d06-7394-f640-319c-b86de05c6682/",
                "local_datetime": "2022-12-19T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-12-20T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }, {
            "theater": {
                "id": "5c0ac706-7394-f640-3136-35317a554046",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/5c0ac706-7394-f640-3136-35317a554046/",
                "title": "AMC THE GROVE 14",
                "name": "AMC THE GROVE 14",
                "city": {
                    "id": "634eb806-59fd-a020-494a-309f1e442649",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/634eb806-59fd-a020-494a-309f1e442649/",
                    "title": "Los Angeles",
                    "name": "Los Angeles",
                    "slug": "los-angeles",
                    "timezone": "America/Los_Angeles"
                },
                "address": "189 THE GROVE DRIVE\r\nLOS ANGELES, CA  90036",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "429b4e06-7394-f640-31ef-1da6e05c6676",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/429b4e06-7394-f640-31ef-1da6e05c6676/",
                "local_datetime": "2023-01-02T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2023-01-03T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }, {
            "theater": {
                "id": "41766206-7394-f640-314e-befffe5c673f",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/41766206-7394-f640-314e-befffe5c673f/",
                "title": "The Landmark Westwood",
                "name": "The Landmark Westwood",
                "city": {
                    "id": "634eb806-59fd-a020-494a-309f1e442649",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/634eb806-59fd-a020-494a-309f1e442649/",
                    "title": "Los Angeles",
                    "name": "Los Angeles",
                    "slug": "los-angeles",
                    "timezone": "America/Los_Angeles"
                },
                "address": "1045 Broxton Ave #2803\r\nLos Angeles, CA 90024",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "7fd66506-7394-f640-310e-8e993a5c67ad",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/7fd66506-7394-f640-310e-8e993a5c67ad/",
                "local_datetime": "2023-01-09T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2023-01-10T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }, {
            "theater": {
                "id": "d915a506-59fd-a020-494a-4d2eae442609",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/d915a506-59fd-a020-494a-4d2eae442609/",
                "title": "Dolby 24 Screening Room",
                "name": "Dolby 24 Screening Room",
                "city": {
                    "id": "63eec106-59fd-a020-494a-25d386442656",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/63eec106-59fd-a020-494a-25d386442656/",
                    "title": "New York",
                    "name": "New York",
                    "slug": "new-york",
                    "timezone": "America/New_York"
                },
                "address": "1350 6th Ave, 28th Fl \r\nNew York, NY 10019",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "c7daa906-59fd-a020-4985-2f303849b9a1",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/c7daa906-59fd-a020-4985-2f303849b9a1/",
                "local_datetime": "2022-11-29T18:00:00-05:00",
                "local_timezone": "EST",
                "utc_datetime": "2022-11-29T23:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "JOIN WAITLIST",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }, {
            "theater": {
                "id": "13d8c006-59fd-a020-494a-4e782c442678",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/13d8c006-59fd-a020-494a-4e782c442678/",
                "title": "Dolby 88 Screening Room",
                "name": "Dolby 88 Screening Room",
                "city": {
                    "id": "63eec106-59fd-a020-494a-25d386442656",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/63eec106-59fd-a020-494a-25d386442656/",
                    "title": "New York",
                    "name": "New York",
                    "slug": "new-york",
                    "timezone": "America/New_York"
                },
                "address": "1350 6th Ave, Ground Level \r\nNew York, NY 10019",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "f86f5706-59fd-a020-4972-86f6a049b9f5",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/f86f5706-59fd-a020-4972-86f6a049b9f5/",
                "local_datetime": "2022-11-16T18:00:00-05:00",
                "local_timezone": "EST",
                "utc_datetime": "2022-11-16T23:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "JOIN WAITLIST",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "1def2b06-59fd-a020-4995-3fbb0a49b9b9",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/1def2b06-59fd-a020-4995-3fbb0a49b9b9/",
                "local_datetime": "2022-12-09T19:00:00-05:00",
                "local_timezone": "EST",
                "utc_datetime": "2022-12-10T00:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "JOIN WAITLIST",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "9d726006-59fd-a020-49a6-061e5249b90f",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/9d726006-59fd-a020-49a6-061e5249b90f/",
                "local_datetime": "2022-12-21T18:00:00-05:00",
                "local_timezone": "EST",
                "utc_datetime": "2022-12-21T23:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "cb334306-59fd-a020-4964-6cd6c449ba1d",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/cb334306-59fd-a020-4964-6cd6c449ba1d/",
                "local_datetime": "2023-01-04T19:00:00-05:00",
                "local_timezone": "EST",
                "utc_datetime": "2023-01-05T00:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "a2140206-59fd-a020-497f-a22af249ba84",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/a2140206-59fd-a020-497f-a22af249ba84/",
                "local_datetime": "2023-01-10T19:00:00-05:00",
                "local_timezone": "EST",
                "utc_datetime": "2023-01-11T00:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }, {
            "theater": {
                "id": "7a7a1006-59fd-a020-4934-3c4d7a49a3b4",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/7a7a1006-59fd-a020-4934-3c4d7a49a3b4/",
                "title": "AMC Bay Street",
                "name": "AMC Bay Street",
                "city": {
                    "id": "c7f2cd06-59fd-a020-494a-320e3044267b",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/c7f2cd06-59fd-a020-494a-320e3044267b/",
                    "title": "San Francisco",
                    "name": "San Francisco",
                    "slug": "san-francisco",
                    "timezone": "America/Los_Angeles"
                },
                "address": "5614 Bay St UNIT 220, \r\nEmeryville, CA 94608",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "ced1a006-59fd-a020-49b7-e43a5849bbec",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/ced1a006-59fd-a020-49b7-e43a5849bbec/",
                "local_datetime": "2022-11-16T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-11-17T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "b6253e06-59fd-a020-49b3-207ae449bccc",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/b6253e06-59fd-a020-49b3-207ae449bccc/",
                "local_datetime": "2023-01-04T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2023-01-05T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }, {
            "theater": {
                "id": "c49cff06-59fd-a020-494a-3ae334442671",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/theaters/c49cff06-59fd-a020-494a-3ae334442671/",
                "title": "AMC Metreon",
                "name": "AMC Metreon",
                "city": {
                    "id": "c7f2cd06-59fd-a020-494a-320e3044267b",
                    "resource_url": "https://paramount.screenings.realpie.com/api/v1/cities/c7f2cd06-59fd-a020-494a-320e3044267b/",
                    "title": "San Francisco",
                    "name": "San Francisco",
                    "slug": "san-francisco",
                    "timezone": "America/Los_Angeles"
                },
                "address": "135 4th St #3000,\r\n San Francisco, CA 94103",
                "phone": "",
                "notes": ""
            }, "screenings": [{
                "id": "26a25406-59fd-a020-49c5-3af24649bb26",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/26a25406-59fd-a020-49c5-3af24649bb26/",
                "local_datetime": "2022-11-29T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-11-30T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "525f0b06-59fd-a020-49ec-69ab6e49bbaf",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/525f0b06-59fd-a020-49ec-69ab6e49bbaf/",
                "local_datetime": "2022-12-05T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-12-06T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "9c305006-59fd-a020-4902-c5f35e49bc1b",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/9c305006-59fd-a020-4902-c5f35e49bc1b/",
                "local_datetime": "2022-12-21T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2022-12-22T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }, {
                "id": "7db09506-59fd-a020-4929-47729649bc99",
                "resource_url": "https://paramount.screenings.realpie.com/api/v1/screenings/7db09506-59fd-a020-4929-47729649bc99/",
                "local_datetime": "2023-01-11T19:00:00-08:00",
                "local_timezone": "PST",
                "utc_datetime": "2023-01-12T03:00:00+00:00",
                "notes": {"qa": {"title": "", "description": ""}, "reception": {"title": "", "description": ""}},
                "status": "open",
                "button_label": "RSVP",
                "button_status": "enabled",
                "screening_type": null,
                "guests_limit": 1,
                "available_affiliations": [{"name": "AACTA Int'l", "slug": "aacta-intl"}, {
                    "name": "AAFCA",
                    "slug": "aafca"
                }, {"name": "ACE", "slug": "ace"}, {"name": "ADG", "slug": "adg"}, {
                    "name": "AFI",
                    "slug": "afi"
                }, {"name": "AMPAS", "slug": "ampas"}, {"name": "ASC", "slug": "asc"}, {
                    "name": "ASCAP",
                    "slug": "ascap"
                }, {"name": "ASIFA", "slug": "asifa"}, {
                    "name": "Atlanta FCC",
                    "slug": "atlanta-fcc"
                }, {"name": "Austin FCA", "slug": "austin-fca"}, {"name": "AWD", "slug": "awd"}, {
                    "name": "BAFTA",
                    "slug": "bafta"
                }, {"name": "BFCA", "slug": "bfca"}, {"name": "BOFCA", "slug": "bofca"}, {
                    "name": "BSFC",
                    "slug": "bsfc"
                }, {"name": "BTL", "slug": "btl"}, {"name": "CAS", "slug": "cas"}, {
                    "name": "CCA",
                    "slug": "cca"
                }, {"name": "CDG", "slug": "cdg"}, {
                    "name": "Chicago Film Critics Association",
                    "slug": "chicago-film-critics-association"
                }, {"name": "Chicago Indie Critics", "slug": "chicago-indie-critics"}, {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                }, {"name": "CSA", "slug": "csa"}, {"name": "DCS", "slug": "dcs"}, {
                    "name": "DGA",
                    "slug": "dga"
                }, {"name": "Film Independent", "slug": "find"}, {"name": "GALECA", "slug": "galeca"}, {
                    "name": "HCA",
                    "slug": "hca"
                }, {"name": "HFPA", "slug": "hfpa"}, {"name": "IATSE 695", "slug": "iatse-695"}, {
                    "name": "ICG",
                    "slug": "icg"
                }, {"name": "IDA", "slug": "ida"}, {"name": "LAFCA", "slug": "lafca"}, {
                    "name": "MPEG",
                    "slug": "mpeg"
                }, {"name": "MPSE", "slug": "mpse"}, {"name": "MUAHS", "slug": "muahs"}, {
                    "name": "NAACP",
                    "slug": "naacp"
                }, {"name": "NARAS", "slug": "naras"}, {"name": "NYFCC", "slug": "nyfcc"}, {
                    "name": "NYFCO",
                    "slug": "nyfco"
                }, {"name": "Other", "slug": "other"}, {"name": "PGA", "slug": "pga"}, {
                    "name": "PSVG",
                    "slug": "psvg"
                }, {"name": "SAG-AFTRA", "slug": "sag-aftra"}, {
                    "name": "SAG AWARDS NOM COMM",
                    "slug": "sag-nom-comm"
                }, {"name": "SAG FILM SOCIETY", "slug": "sag-film-society"}, {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                }, {"name": "SCL", "slug": "scl"}, {"name": "SDSA", "slug": "sdsa"}, {
                    "name": "SOC",
                    "slug": "soc"
                }, {"name": "USC SCRIPTERS", "slug": "usc-scripters"}, {"name": "VES", "slug": "ves"}, {
                    "name": "WGA",
                    "slug": "wga"
                }, {"name": "WIF", "slug": "wif"}]
            }]
        }]
        screenings.convertToScreeningsListingsDisplay()
    },
    manualPopulateRsvp: function () {
        screenings.screeningIdObject = {
            "id": "c135c706-59fd-a020-4910-1227fe35ed87",
            "resource_url": "https://api.paramount.dev.realpie.com/api/v1/screenings/c135c706-59fd-a020-4910-1227fe35ed87/",
            "film": {
                "id": "b55e8d06-59fd-a020-490f-c1e4d835ed03",
                "title": "Tom Gun: Maverick",
                "slug": "top-gun-maverick"
            },
            "local_datetime": "2022-10-04T14:00:00Z",
            "local_timezone": "EDT",
            "utc_datetime": "2022-10-04T18:00:00Z",
            "theater": {
                "id": "da534e06-59fd-a020-490f-fc666235ed0e",
                "resource_url": "https://api.paramount.dev.realpie.com/api/v1/theaters/da534e06-59fd-a020-490f-fc666235ed0e/",
                "name": "RealD",
                "address": "100 N Crescent Drive\r\nBeverly Hills",
                "city": {
                    "id": "8bd91206-59fd-a020-490f-c7ca6035ed98",
                    "resource_url": "https://api.paramount.dev.realpie.com/api/v1/cities/8bd91206-59fd-a020-490f-c7ca6035ed98/",
                    "name": "Beverly Hills",
                    "timezone": "America/New_York"
                }
            },
            "status": "open",
            "guests_limit": 1,
            "available_affiliations": [
                {
                    "name": "AACTA Int'l",
                    "slug": "aacta-intl"
                },
                {
                    "name": "ACE",
                    "slug": "ace"
                },
                {
                    "name": "ADG",
                    "slug": "adg"
                },
                {
                    "name": "AFI Awards Jury",
                    "slug": "afi"
                },
                {
                    "name": "AMPAS",
                    "slug": "ampas"
                },
                {
                    "name": "ASC",
                    "slug": "asc"
                },
                {
                    "name": "ASCAP",
                    "slug": "ascap"
                },
                {
                    "name": "ASIFA",
                    "slug": "asifa"
                },
                {
                    "name": "AWD",
                    "slug": "awd"
                },
                {
                    "name": "BAFTA",
                    "slug": "bafta"
                },
                {
                    "name": "BFCA",
                    "slug": "bfca"
                },
                {
                    "name": "BTL",
                    "slug": "btl"
                },
                {
                    "name": "CAS",
                    "slug": "cas"
                },
                {
                    "name": "CDG",
                    "slug": "cdg"
                },
                {
                    "name": "CSA",
                    "slug": "csa"
                },
                {
                    "name": "Critics Groups",
                    "slug": "critics-groups"
                },
                {
                    "name": "DCS",
                    "slug": "dcs"
                },
                {
                    "name": "DGA",
                    "slug": "dga"
                },
                {
                    "name": "Film Independent",
                    "slug": "find"
                },
                {
                    "name": "HFPA",
                    "slug": "hfpa"
                },
                {
                    "name": "IATSE 695",
                    "slug": "iatse-695"
                },
                {
                    "name": "ICG",
                    "slug": "icg"
                },
                {
                    "name": "IDA",
                    "slug": "ida"
                },
                {
                    "name": "MPEG",
                    "slug": "mpeg"
                },
                {
                    "name": "MPSE",
                    "slug": "mpse"
                },
                {
                    "name": "MUAHS",
                    "slug": "muahs"
                },
                {
                    "name": "NAACP",
                    "slug": "naacp"
                },
                {
                    "name": "NARAS",
                    "slug": "naras"
                },
                {
                    "name": "NYFCO",
                    "slug": "nyfco"
                },
                {
                    "name": "Other",
                    "slug": "other"
                },
                {
                    "name": "PGA",
                    "slug": "pga"
                },
                {
                    "name": "PSVG",
                    "slug": "psvg"
                },
                {
                    "name": "SAG FILM SOCIETY",
                    "slug": "sag-film-society"
                },
                {
                    "name": "SAG FOUNDATION",
                    "slug": "sag-foundation"
                },
                {
                    "name": "SAG NOM COMM",
                    "slug": "sag-nom-comm"
                },
                {
                    "name": "SAG-AFTRA",
                    "slug": "sag-aftra"
                },
                {
                    "name": "SCL",
                    "slug": "scl"
                },
                {
                    "name": "SDSA",
                    "slug": "sdsa"
                },
                {
                    "name": "SOC",
                    "slug": "soc"
                },
                {
                    "name": "USC SCRIPTERS",
                    "slug": "usc-scripters"
                },
                {
                    "name": "VES",
                    "slug": "ves"
                },
                {
                    "name": "WGA",
                    "slug": "wga"
                },
                {
                    "name": "WIF",
                    "slug": "wif"
                }
            ]
        }
    }

}
export default screenings
