// core version + navigation, pagination modules:
import Swiper, { Navigation, Controller } from 'imports/swiper'
let gallery = {
    swiperThumbs:null,
    swiperBig:null,
    init: function () {
        gallery.swiperThumbs = new Swiper(".gallery-thumbs", {
            modules: [Navigation, Controller],
            init:false,
            loop: true,
            loopedSlides: 10,
            spaceBetween: 20,
            slidesPerView: 5,
            slideToClickedSlide: true,
            //centeredSlides: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                // when window width is >= Xpx
                0: {
                    slidesPerView: 3,
                },
                900: {
                    slidesPerView: 5,
                },
            }
        })
        gallery.swiperThumbs.on('afterInit', gallery.init2)
        gallery.swiperThumbs.init()
    },
    init2: function () {
        gallery.swiperBig  = new Swiper(".gallery-big", {
            modules: [Controller],
            init:false,
            loop: true,
            loopedSlides: 10,
            //centeredSlides: true,
            spaceBetween: 0,
            slidesPerView: 1
        })
        gallery.swiperBig.on('afterInit', gallery.init3)
        gallery.swiperBig.init()
    },
    init3: function () {
        gallery.swiperThumbs.controller.control = gallery.swiperBig
        gallery.swiperBig.controller.control = gallery.swiperThumbs
    }
}
export default gallery
