//import $ from 'imports/jquery'
import { gsap, CSSPlugin, ScrollToPlugin } from 'imports/gsap/all'
gsap.registerPlugin(ScrollToPlugin);
let global = {
    init: function () {
        $('.header .sign-up').on('click',global.gotoSignup)
    },
    ////////////////////////////////////////////////////////////////////////////////////
    gotoSignup: function () {
        gsap.to(window, {duration: 1, scrollTo: {y: '#mc-email-classy', offsetY: 0}});
    },
}
export default global
